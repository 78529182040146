import * as t from 'io-ts'

export const TDescription = t.type({
  descriptionUA: t.string,
  descriptionRU: t.string,
  descriptionEN: t.string,
})

export const TBranch = t.type({
  additionalInfo: t.union([t.string, t.undefined]),
  branchId: t.string,
  branchNumber: t.number,
  branchNumberSystem: t.union([t.string, t.undefined]),
  branchType: t.type({
    branchTypeId: t.string,
    branchType: t.string,
    weightLimit: t.string,
  }),
  region: t.type({
    addressDetailsId: t.string,
    addressType: TDescription,
    descriptionUA: t.string,
    descriptionRU: t.string,
    descriptionEN: t.union([t.string, t.undefined]),
  }),
  district: t.type({
    addressDetailsId: t.string,
    addressType: TDescription,
    descriptionUA: t.string,
    descriptionRU: t.string,
    descriptionEN: t.union([t.string, t.undefined]),
  }),
  city: t.type({
    addressDetailsId: t.string,
    addressType: TDescription,
    descriptionUA: t.string,
    descriptionRU: t.string,
    descriptionEN: t.union([t.string, t.undefined]),
  }),
  street: t.type({
    addressDetailsId: t.string,
    addressType: TDescription,
    descriptionUA: t.union([t.string, t.undefined]),
    descriptionRU: t.union([t.string, t.undefined]),
    descriptionEN: t.union([t.string, t.undefined]),
  }),
  building: t.union([t.string, t.undefined]),
  closed: t.boolean,
  weightMax: t.union([t.number, t.undefined]),
  insuranceMax: t.number,
})

export type Branch = t.TypeOf<typeof TBranch>

export const TBranchIdentificationType = t.type({
  name: t.string,
  value: t.union([t.literal('CALL'), t.literal('SMS')]),
})
