import { Stack, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { myNotificationsPath } from 'app/app-menu'
import { useAuthContext } from 'app/auth'
import { TNotificationsViewed } from 'app/codecs/notifications'
import { isAdminRole } from 'app/codecs/user'
import { IconNewNotifications } from 'assets/icons'
import { NewNotificationSnackbar } from 'components/new-notification-snackbar'
import { useBooleanContext } from 'hooks/use-boolean-context'
import { array, number, type } from 'io-ts'
import { useQuery } from 'lib/rest-query'
import { concatQueryParams } from 'lib/rest-query/common'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

export const PARCEL_COUNT_QUERY_KEY = 'PARCEL_COUNT'

type Props = {
  branchId: string
  isLoginChecker?: boolean
}
export const AppCountBranchParcels = ({ branchId, isLoginChecker }: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const { auth } = useAuthContext()
  const history = useHistory()

  const url = concatQueryParams(
    'api/pudo/parcels/quantity',
    new URLSearchParams({
      branchId: branchId ?? '',
    }),
  )

  const $parcelQuantity = useQuery(
    'GET',
    url,
    type({
      expectedParcels: number,
      parcelsAtBranch: number,
    }),
    {
      customQueryKey: [PARCEL_COUNT_QUERY_KEY, branchId],
    },
  )

  const $productsQuantity = useQuery(
    'GET',
    `api/pudo/product/quantity/${branchId}`,
    type({
      productsAtBranch: number,
    }),
  )

  const userId = auth.type === 'authenticated' ? auth.userId : ''

  const notificationQueryParams = new URLSearchParams({
    limit: '1',
  })

  const $getAllNotifications = useQuery(
    'POST',
    concatQueryParams('/api/pudo/notification/isView', notificationQueryParams),
    array(TNotificationsViewed),
    {
      rawBody: {
        isView: ['VIEWED', 'NOT_VIEWED'],
        userId: userId,
      },
    },
  )

  const notViewedNotification = ($getAllNotifications.data || []).filter(
    notification => !notification.viewed,
  ).length

  const newestNotification = $getAllNotifications.data
    ? $getAllNotifications.data.find(notification => !notification.viewed)
    : undefined

  const count = $parcelQuantity.data

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(true)

  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const drawer = useBooleanContext()

  if (!count) {
    return null
  }

  return (
    <>
      <Stack
        mt={isAdminRole(auth.role) ? 0 : 1}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="caption1" color="#89A6CC">
          {t('ui.balance_of_parcels')}
        </Typography>
        <Typography component="span" variant="caption1" color="#89A6CC">
          {count.parcelsAtBranch}
        </Typography>
      </Stack>
      <Stack
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="caption1" color="#89A6CC">
          {t('ui.balance_of_parcels_to_the_branch')}
        </Typography>
        <Typography component="span" variant="caption1" color="#89A6CC">
          {$productsQuantity.data?.productsAtBranch}
        </Typography>
      </Stack>
      {!isAdminRole(auth.role) && notViewedNotification !== undefined && (
        <Stack
          display="flex"
          mt={1}
          px={2}
          py={notViewedNotification > 0 ? 0 : 1}
          flexDirection="row"
          onClick={() => {
            if (mdDown) drawer.setFalse()
            history.push(myNotificationsPath)
          }}
          alignItems="center"
          justifyContent="space-between"
          color={'white'}
          sx={{
            cursor: 'pointer',
            bgcolor: theme.palette.primary.main,
            borderRadius: '10px',
            '&:hover': {
              bgcolor: theme.palette.primary.dark,
            },
          }}
        >
          <Typography variant="caption1">
            {t('ui.view_new_notifications')}
          </Typography>

          {notViewedNotification > 0 && (
            <IconNewNotifications value={notViewedNotification.toString()} />
          )}
          {notViewedNotification == 0 && (
            <Typography component="span">
              {notViewedNotification.toString()}
            </Typography>
          )}
        </Stack>
      )}
      {!isAdminRole(auth.role) &&
        newestNotification !== undefined &&
        isLoginChecker && (
          <NewNotificationSnackbar
            isOpen={isSnackbarOpen}
            onClose={() => setIsSnackbarOpen(false)}
            newestNotification={newestNotification}
          />
        )}
    </>
  )
}
