import { LazyRoute, lazyRoute } from 'lib/lazy-routing'
import {
  childPageGenerator,
  simplePageGenerator,
} from 'lib/route-generator/page-generator'

import { routes } from './routes'

const generateChildPage = childPageGenerator(routes)

const generateSimplePage = simplePageGenerator(routes)

export const lazyRouteById = (id: keyof typeof routes) => {
  return lazyRoute({
    title: id,
    path: routes[id],
    factory: () => import(`pages/${id}`),
  })
}

export const pageRoutesOperator: Array<LazyRoute> = [
  ...generateSimplePage('client-parcel-reception'),
  ...generateSimplePage('courier-parcel-issuance'),
  ...generateSimplePage('courier-parcel-reception'),
  ...generateSimplePage('client-parcel-issuance'),
  ...generateSimplePage('container-selling'),
  ...generateSimplePage('inventory'),
  ...generateSimplePage('documents'),
  ...generateSimplePage('terminals'),
  ...generateSimplePage('client-parcel-return'),
  ...generateSimplePage('branches'),
  ...generateSimplePage('calculator'),
  ...generateSimplePage('faq'),
  ...generateSimplePage('cashbox'),
  ...generateSimplePage('my-notifications'),
  ...generateSimplePage('home'),
]

export const pageRoutesAdmin: Array<LazyRoute> = [
  ...generateSimplePage('users'),
  ...generateSimplePage('revision'),
  ...generateSimplePage('client-parcel-reception'),
  ...generateSimplePage('courier-parcel-issuance'),
  ...generateSimplePage('courier-parcel-reception'),
  ...generateSimplePage('client-parcel-issuance'),
  ...generateSimplePage('documents'),
  ...generateSimplePage('terminals'),
  ...generateSimplePage('branches'),
  ...generateSimplePage('faq'),
  ...generateSimplePage('cashbox'),
  ...generateSimplePage('create-notification'),
  ...generateSimplePage('archive-notification'),
  ...generateChildPage('users', 'events'),
]

export const pageRoutesUnauth: Array<LazyRoute> = [lazyRouteById('login')]

export const allPageRoutes = [
  ...pageRoutesAdmin,
  ...pageRoutesUnauth,
  ...pageRoutesOperator,
]
