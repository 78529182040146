import { routes } from 'app/routes'
import {
  IconArchiveNotification,
  IconBranches,
  IconCalculator,
  IconCashbox,
  IconClientParcelIssuance,
  IconClientParcelReception,
  IconCourierOperations,
  IconCourierParcelGive,
  IconCourierParcelIssuance,
  IconCreateNotification,
  IconDocuments,
  IconFAQ,
  IconInventory,
  IconNotification,
  IconParcelReturn,
  IconSelfServiceTerminals,
  IconUsers,
} from 'assets/icons'
import { IconContainerSelling } from 'assets/icons/container-selling'

export const menuItemsAdmin = [
  {
    title: 'menu_items.users',
    path: routes.users,
    Icon: IconUsers,
  },
  {
    title: 'menu_items.revision',
    path: routes.revision,
    Icon: IconUsers,
  },
  {
    title: 'menu_items.documents_in_branches',
    path: routes.documents,
    Icon: IconDocuments,
  },
  {
    title: 'menu_items.faq',
    path: routes.faq,
    Icon: IconFAQ,
  },
  {
    title: 'menu_items.notification',
    Icon: IconNotification,
    items: [
      {
        title: 'menu_items.create_notification',
        Icon: IconCreateNotification,
        path: routes.createNotification,
      },
      {
        title: 'menu_items.archive_notification',
        Icon: IconArchiveNotification,
        path: routes.archiveNotification,
      },
    ],
  },
] as const

export const myNotificationsPath = routes.myNotifications

export const adminDefaultPath = routes.users

export const operatorDefaultPath = routes.home

export const cashBoxPath = routes.cashbox

export const menuItemsOperator = [
  {
    title: 'menu_items.parcel_reception',
    path: routes.clientParcelReception,
    Icon: IconClientParcelReception,
  },
  {
    title: 'menu_items.client_parcel_issuance',
    path: routes.clientParcelIssuance,
    Icon: IconClientParcelIssuance,
  },
  {
    title: 'menu_items.parcel_return',
    path: routes.clientParcelReturn,
    Icon: IconParcelReturn,
  },
  {
    title: 'menu_items.operations_with_courier',
    Icon: IconCourierOperations,
    items: [
      {
        title: 'menu_items.courier_parcel_issuance',
        Icon: IconCourierParcelGive,
        path: routes.courierParcelIssuance,
      },
      {
        title: 'menu_items.receive-parcels-from-courier',
        Icon: IconCourierParcelIssuance,
        path: routes.courierParcelReception,
      },
    ],
  },
  {
    title: 'menu_items.cashbox',
    path: routes.cashbox,
    Icon: IconCashbox,
  },
  {
    title: 'menu_items.container_selling',
    path: routes.containerSelling,
    Icon: IconContainerSelling,
  },
  {
    title: 'menu_items.inventory',
    path: routes.inventory,
    Icon: IconInventory,
  },
  {
    title: 'menu_items.documents',
    path: routes.documents,
    Icon: IconDocuments,
  },
  {
    title: 'menu_items.terminals',
    path: routes.terminals,
    Icon: IconSelfServiceTerminals,
  },
  {
    title: 'menu_items.branches',
    path: routes.branches,
    Icon: IconBranches,
  },
  {
    title: 'menu_items.calculator',
    path: routes.calculator,
    Icon: IconCalculator,
  },
  {
    title: 'menu_items.faq',
    path: routes.faq,
    Icon: IconFAQ,
  },
] as const
