import * as t from 'io-ts'
import { DateFromISOString, optionFromNullable } from 'io-ts-types'

import { TBranch } from '../branch'

export const TUserScopes = t.array(t.string)

export const TUserRole = t.union([
  t.literal('SUPER_ADMIN'),
  t.literal('ADMIN'),
  t.literal('OPERATOR'),
  t.literal('CONTENT_MANGER'),
])

export const TUserStatus = t.union([t.literal('ACTIVE'), t.literal('INACTIVE')])

export const TUserRoleComplex = t.type({
  role: TUserRole,
  scopes: TUserScopes,
  extraScopes: t.array(t.string),
})

export const TUser = t.type({
  email: t.union([t.string, t.undefined]),
  fullName: t.union([t.string, t.undefined]),
  lastPasswordChange: optionFromNullable(DateFromISOString),
  creatorPhoneNumber: t.union([t.string, t.undefined]),
  branchResponse: t.union([TBranch, t.undefined]),
  lastVisited: optionFromNullable(DateFromISOString),
  phone: t.string,
  role: TUserRoleComplex,
  status: TUserStatus,
  userId: t.string,
  printer: t.boolean,
  pos: t.union([t.boolean, t.undefined]),
  sale: t.boolean,
  tips: t.union([t.boolean, t.undefined]),
})

export const TProfile = t.type({
  userId: t.string,
  email: t.union([t.string, t.undefined]),
  phone: t.string,
  fullName: t.union([t.string, t.undefined]),
  branchResponse: t.union([TBranch, t.undefined]),
  printer: t.boolean,
  pos: t.union([t.boolean, t.undefined]),
  sale: t.union([t.boolean, t.undefined]),
})

export type UserRole = t.TypeOf<typeof TUserRole>

export type UserStatus = t.TypeOf<typeof TUserStatus>

type Handlers<O, A, S, C> = {
  onOperator: () => O
  onAdmin: () => A
  onSuperAdmin: () => S
  onContentManager: () => C
}

export const foldUserRole =
  <O, A, S, C>(handlers: Handlers<O, A, S, C>) =>
  (role: UserRole | null) => {
    if (role === 'ADMIN') {
      return handlers.onAdmin()
    }

    if (role === 'OPERATOR') {
      return handlers.onOperator()
    }

    if (role === 'SUPER_ADMIN') {
      return handlers.onSuperAdmin()
    }

    if (role === 'CONTENT_MANGER') {
      return handlers.onContentManager()
    }

    return null
  }

export const isContentManagerRole = (role: UserRole | null) =>
  role === 'CONTENT_MANGER'

export const isAdminRole = (role: UserRole | null) =>
  role === 'ADMIN' || role === 'SUPER_ADMIN'

export const isSuperAdminRole = (role: UserRole | null) =>
  role === 'SUPER_ADMIN'
