import { LoadingButton } from '@material-ui/lab'
import { ControlledSelectReason } from 'components/form/controlled-select-reason'
import { FormField } from 'components/form/form-field'
import {
  ParcelIdentificationFieldValues,
  UnpackFieldValues,
} from 'components/forms/parcel-actions/parcel-actions-form-dialog'
import { FormDialog } from 'components/ui/form-dialog/form-dialog'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { MeestVerification } from 'meest-domain/utils'
import { useCallback } from 'react'
import { Control, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = {
  parcelId: string
  isOpen: boolean
  onClose: () => void
  onSuccess: (parcelId: string) => void
  branchId: string
  identificationControl: Control<ParcelIdentificationFieldValues>
  identificationFormValues: ParcelIdentificationFieldValues
  unpackChoices: UnpackFieldValues | undefined
  clientPhone?: string | null
}

type FormValues = MeestVerification & {
  reason:
    | 'REFUSAL_PAY'
    | 'PRODUCT_NOT_MATCH_ORDER'
    | 'CARGO_DAMAGED'
    | 'ORDER_NOT_FIT'
    | 'ORDER_NOT_RELEVANT'
    | null
  unpackChoices: UnpackFieldValues
}

export const ParcelRefusalDialog = ({
  isOpen,
  parcelId,
  branchId,
  onSuccess,
  onClose,
  unpackChoices,
}: Props) => {
  const { t } = useTranslation()

  const defaultValues = {
    reason: null,
    unpackChoices,
  }

  const form = useForm<FormValues>({
    defaultValues,
  })

  const $parcelsRefusal = useMutation('PUT', '/api/pudo/parcels/refusal')

  const parcelsRefusalMutate = $parcelsRefusal.mutate

  const onSubmit = useCallback(
    (formValues: FormValues) => {
      if (formValues.reason) {
        parcelsRefusalMutate(
          {
            body: {
              parcelIds: [
                {
                  parcelId,
                  unpacking: formValues.unpackChoices?.[parcelId] === 'true',
                },
              ],
              reason: formValues.reason,
            },
            search: new URLSearchParams({ branchId }),
          },
          {
            onSuccess: () => {
              onSuccess(parcelId)
              onClose()
            },
          },
        )
      }
    },
    [branchId, onClose, onSuccess, parcelId, parcelsRefusalMutate],
  )

  return (
    <FormDialog
      title={t('dialog_titles.refusal_parcel')}
      open={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      closeDialogTitle={'dialog_confirm.refuse_parcel'}
      form={form}
      actions={
        <LoadingButton
          loading={$parcelsRefusal.isLoading}
          fullWidth
          type="submit"
        >
          {t('dialog_actions.confirm')}
        </LoadingButton>
      }
    >
      <FormField>
        <ControlledSelectReason
          required
          reasons={[
            {
              title: t('refusal_parcel_reasons.refusal_pay'),
              value: 'REFUSAL_PAY',
            },
            {
              title: t('refusal_parcel_reasons.product_not_fit'),
              value: 'ORDER_NOT_FIT',
            },
            {
              title: t('refusal_parcel_reasons.product_not_match_order'),
              value: 'PRODUCT_NOT_MATCH_ORDER',
            },
            {
              title: t('refusal_parcel_reasons.cargo_damaged'),
              value: 'CARGO_DAMAGED',
            },
            {
              title: t('refusal_parcel_reasons.order_not_relevant'),
              value: 'ORDER_NOT_RELEVANT',
            },
          ]}
          label={t('form_fields.specify_refusal_reason')}
          control={form.control}
          name="reason"
        />
      </FormField>
    </FormDialog>
  )
}
